// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_AT_RULE_IMPORT_0___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!../../node_modules/rsuite/dist/rsuite.css";
import ___CSS_LOADER_AT_RULE_IMPORT_1___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./reset.css";
import ___CSS_LOADER_AT_RULE_IMPORT_2___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./colors.css";
import ___CSS_LOADER_AT_RULE_IMPORT_3___ from "-!../../node_modules/css-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[1]!../../node_modules/postcss-loader/dist/cjs.js??ruleSet[1].rules[1].oneOf[5].use[2]!./loader.css";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500;700&family=Jost:wght@400;600;700&family=Lexend:wght@500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500;700&family=Lexend:wght@500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Text:wght@400;500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap);"]);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_0___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_1___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_2___);
___CSS_LOADER_EXPORT___.i(___CSS_LOADER_AT_RULE_IMPORT_3___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `html,
body {
  font-family: 'Geologica';
  background-color: #f9f9f9;
  font-size: 16px;
  line-height: 1.24;
  color: var(--black);
}

span {
  color: var(--black);
}

h1,
h2 {
  color: var(--black);
}

h2 {
  font-size: 16px;
  line-height: 1;
}

p {
  font-weight: 500;
}

p + p {
  margin: 0;
}

a {
  display: block;
  width: -moz-fit-content;
  width: fit-content;
  color: var(--purple);
  text-decoration: none;

  &:visited, &:focus {
    color: var(--purple);
    text-decoration: none;
  }

  &:hover {
    color: var(--deep-blue);
    text-decoration: none;
  }
}

td {
  vertical-align: middle;
}

.actions {
  text-align: center;
}`, "",{"version":3,"sources":["webpack://./src/styles/index.css"],"names":[],"mappings":"AAUA;;EAEE,wBAAwB;EACxB,yBAAyB;EACzB,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;;EAEE,mBAAmB;AACrB;;AAEA;EACE,eAAe;EACf,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,SAAS;AACX;;AAEA;EACE,cAAc;EACd,uBAAkB;EAAlB,kBAAkB;EAClB,oBAAoB;EACpB,qBAAqB;;EAErB;IACE,oBAAoB;IACpB,qBAAqB;EACvB;;EAEA;IACE,uBAAuB;IACvB,qBAAqB;EACvB;AACF;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,kBAAkB;AACpB","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500;700&family=Jost:wght@400;600;700&family=Lexend:wght@500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=IBM+Plex+Mono:wght@500;700&family=Lexend:wght@500;700&family=Poppins:wght@100;200;300;400;500;600;700;800;900&family=Red+Hat+Text:wght@400;500&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Geologica:wght@100..900&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');\n\n@import 'rsuite/dist/rsuite.css';\n@import 'reset.css';\n@import 'colors.css';\n@import 'loader.css';\n\nhtml,\nbody {\n  font-family: 'Geologica';\n  background-color: #f9f9f9;\n  font-size: 16px;\n  line-height: 1.24;\n  color: var(--black);\n}\n\nspan {\n  color: var(--black);\n}\n\nh1,\nh2 {\n  color: var(--black);\n}\n\nh2 {\n  font-size: 16px;\n  line-height: 1;\n}\n\np {\n  font-weight: 500;\n}\n\np + p {\n  margin: 0;\n}\n\na {\n  display: block;\n  width: fit-content;\n  color: var(--purple);\n  text-decoration: none;\n\n  &:visited, &:focus {\n    color: var(--purple);\n    text-decoration: none;\n  }\n\n  &:hover {\n    color: var(--deep-blue);\n    text-decoration: none;\n  }\n}\n\ntd {\n  vertical-align: middle;\n}\n\n.actions {\n  text-align: center;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
