import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { addRoute } from './breadcrumbsSlice'
import { store } from './store'

const RouteTracker = () => {
  const location = useLocation()
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(addRoute(location.pathname))
  }, [location.pathname, dispatch])

  return null
}

export default RouteTracker
