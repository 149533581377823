import { lazy } from 'react'
import { Route, Navigate } from 'react-router-dom'

const Dashboard = lazy(() => import('pages/admin/dashboard'))
const EmployeesOnProbation = lazy(() =>
  import('pages/admin/employeesOnProbation')
)
const AbsentEmployees = lazy(() => import('pages/admin/absentEmployees'))

const Profile = lazy(() => import('pages/admin/profile'))
const ProfileGlobal = lazy(() =>
  import('components/admin/Profile/ProfileGlobal')
)
const ProfileDocuments = lazy(() =>
  import('components/admin/Profile/ProfileDocuments/ProfileDocuments')
)

const ProfileEvents = lazy(() =>
  import('components/admin/Profile/ProfileEvents/index.js')
)
const ProfileRequest = lazy(() =>
  import('components/admin/Profile/ProfileRequest/ProfileRequest')
)
const ProfileDevices = lazy(() =>
  import('components/admin/Profile/ProfileDevices/ProfileDevices')
)
const ProfileNotes = lazy(() =>
  import('components/admin/Profile/ProfileNotes/ProfileNotes')
)
const ProfileJobOffers = lazy(() => import('components/admin/Profile/ProfileJobOffers/JobOffers'))
const AddJobOffer = lazy(() => import('pages/admin/addJobOffer'))
const EditJobOffer = lazy(() => import('pages/admin/editJobOffer'))
const JobOfferProfile = lazy(() => import('pages/admin/JobOfferProfile'))

const AddRequest = lazy(() => import('pages/admin/addRequest'))
const EditRequest = lazy(() => import('pages/admin/editRequest'))
const AddEvent = lazy(() => import('pages/admin/addEvent'))
const EditEvent = lazy(() => import('pages/admin/editEvent'))

const Teams = lazy(() => import('pages/admin/allTeams'))
const TeamEmployee = lazy(() => import('components/admin/Team/Employee'))
const AllTeams = lazy(() => import('components/admin/Team/AllTeams'))
const MyTeams = lazy(() => import('components/admin/Team/MyTeams'))
const TeamPage = lazy(() => import('pages/admin/teamPage'))
const AddTeam = lazy(() => import('pages/admin/addTeam'))
const EditTeam = lazy(() => import('pages/admin/editTeam'))
const AddEmployee = lazy(() => import('pages/admin/addEmployee'))
const EditEmployee = lazy(() => import('pages/admin/editEmployee'))
// const Login = lazy(() => import('pages/admin/login'))
// const ForgotPassword = lazy(() => import('pages/admin/forgotPassword'))

const Recruiting = lazy(() => import('pages/admin/recruiting'))
const Vacancies = lazy(() =>
  import('components/admin/Recruiting/RecruitingVacancies/Vacancies')
)
const RecruitingDocuments = lazy(() =>
  import('components/admin/Recruiting/RecruitingDocuments/RecruitingDocuments')
)
const AddRecruitingDocument = lazy(() =>
  import('pages/admin/addRecruitingDocument')
)
const EditRecruitingDocument = lazy(() =>
  import('pages/admin/editReruitingDocument')
)
const DocumentProfile = lazy(() => import('pages/admin/documentProfile'))
const Candidates = lazy(() =>
  import('components/admin/Recruiting/RecruitingCandidates/Candidates')
)
const AddCandidate = lazy(() => import('pages/admin/addCandidate'))
const EditCandidate = lazy(() => import('pages/admin/editCandidate'))
const CandidateProfile = lazy(() => import('pages/admin/candidateProfile'))
const CandidateInfo = lazy(() =>
  import('components/admin/CandidateProfile/CandidateInfo')
)
const CandidateEvents = lazy(() =>
  import('components/admin/CandidateProfile/CandidateEvents')
)
const CandidateNotes = lazy(() =>
  import('components/admin/CandidateProfile/CandidateNotes')
)
const AddVacancy = lazy(() => import('pages/admin/addVacancy'))
const EditVacancy = lazy(() => import('pages/admin/editVacancy'))
const VacancyProfile = lazy(() => import('pages/admin/vacancyProfile'))
const VacancyProfileDescription = lazy(() =>
  import('components/admin/VacancyProfile/VacancyDescription')
)
const VacancyProfileCandidates = lazy(() =>
  import('components/admin/VacancyProfile/VacancyCandidates')
)
const VacancyProfileSteps = lazy(() =>
  import('components/admin/VacancyProfile/VacancySteps')
)
const VacancyProfileIntegration = lazy(() =>
  import('components/admin/VacancyProfile/VacancyIntegration')
)
const VacancyProfileNotes = lazy(() =>
  import('components/admin/VacancyProfile/VacancyNotes')
)
const VacancyProfileDocuments = lazy(() =>
  import('components/admin/VacancyProfile/VacancyDocuments')
)
const AddDocument = lazy(() => import(`pages/admin/addDocument`))
const EditDocument = lazy(() => import(`pages/admin/editDocument`))
const CandidatesEvents = lazy(() =>
  import('components/admin/Recruiting/RecruitingEvents/CandidatesEvents')
)
const AddCandidatesEvent = lazy(() => import('pages/admin/addCandidatesEvent'))
const EditCandidatesEvent = lazy(() =>
  import('pages/admin/editCandidatesEvents')
)
const CandidateEventProfile = lazy(() =>
  import('pages/admin/candidateEventProfile')
)
const Hr = lazy(() => import('pages/admin/hr'))
const HrEvents = lazy(() => import('components/admin/Hr/HrEvents/HrEvents'))
const HrPastEmployees = lazy(() =>
  import('components/admin/Hr/HrPastEmployees/HrPastEmployees')
)
const HrEquipment = lazy(() =>
  import('components/admin/Hr/HrEquipment/HrEquipment')
)
const HrRequests = lazy(() =>
  import('components/admin/Hr/HrRequests/HrRequests')
)
const AddHrEvent = lazy(() => import('pages/admin/addHrEvent'))
const EditHrEvent = lazy(() => import('pages/admin/editHrEvent'))
const HrEventProfile = lazy(() => import('pages/admin/hrEventProfile'))
const AddHrRequest = lazy(() => import('pages/admin/addHrRequest'))
const EditHrRequest = lazy(() => import('pages/admin/editHrRequest'))
const HrRequestProfile = lazy(() => import('pages/admin/hrRequestProfile'))
const AddHrEquipment = lazy(() => import('pages/admin/AddHrEquipment'))
const EditHrEquipment = lazy(() => import('pages/admin/editHrEquipment'))
const EquipmentProfile = lazy(() => import(`pages/admin/equipmentProfile`))
const EquipmentHistory = lazy(() => import(`pages/admin/equipmentHistory`))

const Company = lazy(() => import(`pages/admin/company`))
const CompanyCalendar = lazy(() => import(`components/admin/Company/Calendar/WorkCalendar`))
const CompanyCalculator = lazy(() => import(`components/admin/Company/Calculator/CompanyCalculator`))
const CompanySettings = lazy(() => import(`components/admin/Company/Settings/CompanySettings`))

const OpportunitiesPage = lazy(() => import(`pages/admin/opportunities`))
const OpportunitiesComponent = lazy(() => import(`components/admin/Opportunities/Opportunities`))
const OpportunityDetails = lazy(() => import('pages/admin/opportunityDetails'))
const OpportunityDetailsGeneral = lazy(() => import('components/admin/Opportunities/Details/General'))
const OpportunityDetailsCandidates = lazy(() => import('components/admin/Opportunities/Details/Candidates/Candidates'))
const OpportunityDetailsStages = lazy(() => import('components/admin/Opportunities/Details/Stages'))
const OpportunityDetailsIntegrations = lazy(() => import('components/admin/Opportunities/Details/Integrations'))
const OpportunityDetailsComments = lazy(() => import('components/admin/Opportunities/Details/Comments/Comments'))
// const Opportunities = lazy(() => import(`components/admin/Opportunities/Opportunities`))
// ------------------ Analytics ------------------

// const Analytics = lazy(() => import('pages/admin/analytics'))
// const Employees = lazy(() => import('components/admin/Analytics/Employees'))
// const AgeOfEmployees = lazy(() => import('components/admin/Analytics/AgeOfEmployees'))
// const WorkExperience = lazy(() => import('components/admin/Analytics/WorkExperience'))
// const EmployeeTurnover = lazy(() => import('components/admin/Analytics/EmployeeTurnover'))

// ------------------ Settings ------------------

// const Settings = lazy(() => import('pages/admin/settings'))
// const SettingsRecruting = lazy(() => import('pages/admin/settingsRecruting'))

// const SettingsVacations = lazy(() => import('pages/admin/settingsVacations'))
// const Vacations = lazy(() => import('components/admin/Settings/Vacations/Vacations'))
// const Hospitals = lazy(() => import('components/admin/Settings/Vacations/Hospitals'))
// const AtOwnExpense = lazy(() => import('components/admin/Settings/Vacations/AtOwnExpense'))
// const BirthdayDayOff = lazy(() => import('components/admin/Settings/Vacations/BirthdayDayOff'))

// const SettingsCalendars = lazy(() => import('pages/admin/settingsCalendars'))
// const Calendars = lazy(() => import('components/admin/Settings/Calendars/Calendars'))
// const EventDetails = lazy(() => import('pages/admin/eventDetails'))

// const SettingsSurveys = lazy(() => import('pages/admin/settingsSurveys'))
// const Surveys = lazy(() => import('components/admin/Settings/Surveys/Surveys'))
// const SurveyDetails = lazy(() => import('pages/admin/surveyDetails'))
// const AddSurvey = lazy(() => import('pages/admin/addSurveys'))
// const AddForm = lazy(() => import('pages/admin/addForm'))
// const Question = lazy(() => import('components/admin/Settings/Surveys/AddForm/Questions/Question'))
// const Invites = lazy(() => import('components/admin/Settings/Surveys/AddForm/Invites'))

// const SettingsCompanies = lazy(() => import('pages/admin/settingsCompanies'))
// const Companies = lazy(() => import('components/admin/Settings/Companies/Companies/Companies'))
// const AddCompany = lazy(() => import('pages/admin/addCompany'))
// const Projects = lazy(() => import('components/admin/Settings/Companies/Projects/Projects'))
// const AddProject = lazy(() => import('pages/admin/addProject'))
// const CompaniesVacations = lazy(() => import('components/admin/Settings/Companies/Vacations/Vacations'))
// const Stages = lazy(() => import('components/admin/Settings/Companies/Stages/Stages'))



const AdminRoutes = (
  <>
    {/* <Route path='/login' element={<Login />} key='/login'/> */}
    {/* <Route path='/forgot-password' element={<ForgotPassword />} key='/forgot-password' /> */}

    <Route path="/dashboard" element={<Dashboard />} key="/dashboard" />
    <Route
      path="/dashboard/probation-employees"
      element={<EmployeesOnProbation />}
      key="/dashboard"
    />
    <Route
      path="/dashboard/absent-employees"
      element={<AbsentEmployees />}
      key="/dashboard"
    />

    <Route path="/profile" element={<Profile />} key="/profile">
      <Route index element={<Navigate to="/profile/global" />} />
      {/* <Route path="global" element={<ProfileGlobal />} /> */}
      <Route path="global/:uuid" element={<ProfileGlobal />} />
      {/* <Route path="events" element={<ProfileEvents />} /> */}
      <Route path="events/:uuid" element={<ProfileEvents />} />
      {/* <Route path="requests" element={<ProfileRequest />} /> */}
      <Route path="requests/:uuid" element={<ProfileRequest />} />
      {/* <Route path="documents" element={<ProfileDocuments />} /> */}
      <Route path="documents/:uuid" element={<ProfileDocuments />} />
      {/* <Route path="devices" element={<ProfileDevices />} /> */}
      <Route path="devices/:uuid" element={<ProfileDevices />} />
      {/* <Route path="notes" element={<ProfileNotes />} /> */}
      <Route path="notes/:uuid" element={<ProfileNotes />} />
      {/* <Route path="job-offers" element={<ProfileJobOffers />} /> */}
      <Route path="job-offers/:uuid" element={<ProfileJobOffers />} />
    </Route>

    <Route path="/requests/add/:uuid" element={<AddRequest />} key="/request">
      <Route index element={<Navigate to="/requests" />} />
    </Route>

    <Route path="/requests/edit/:uuid" element={<EditRequest />} key="/request">
      <Route index element={<Navigate to="/requests" />} />
    </Route>

    <Route path="/events/add/:uuid" element={<AddEvent />} key="/event">
      <Route index element={<Navigate to="/events" />} />
    </Route>

    <Route path="/events/edit/:uuid" element={<EditEvent />} key="/event">
      <Route index element={<Navigate to="/events" />} />
    </Route>

    <Route path="/job-offers/create/:uuid" element={<AddJobOffer />} key="/job-offer">
      <Route index element={<Navigate to="/job-offer" />} />
    </Route>

    <Route path="/job-offers/edit/:uuid" element={<EditJobOffer />} key="/job-offer">
      <Route index element={<Navigate to="/job-offer" />} />
    </Route>

    <Route path="/job-offers/detail/:uuid" element={<JobOfferProfile />} key="/job-offer">
      <Route index element={<Navigate to="/job-offer" />} />
    </Route>

    <Route path="/teams" element={<Teams />} key="/teams">
      <Route index element={<Navigate to="/teams/all" />} />
      <Route path="all" element={<AllTeams />} />
      <Route path="my" element={<MyTeams />} />
      <Route path="employee" element={<TeamEmployee />} />
    </Route>
    <Route path="/teams/create" element={<AddTeam />} />
    <Route path="/teams/edit/:uuid" element={<EditTeam />} />
    <Route path="/teams/employee/create" element={<AddEmployee />} />
    <Route path="/teams/employee/edit/:uuid" element={<EditEmployee />} />

    <Route path="/teams/:uuid" element={<TeamPage />} key="/team">
      <Route index element={<Navigate to="/team" />} />
    </Route>

    <Route path="/hr" element={<Hr />} key="/hr">
      <Route index element={<Navigate to="/hr/events" />} />
      <Route path="events" element={<HrEvents />} />
      <Route path="past-employees" element={<HrPastEmployees />} />
      <Route path="equipments" element={<HrEquipment />} />
      <Route path="requests" element={<HrRequests />} />
    </Route>

    <Route path="/hr/event/create" element={<AddHrEvent />} />
    <Route path="/hr/event/edit/:uuid" element={<EditHrEvent />} />
    <Route path="/hr/event/profile/:uuid" element={<HrEventProfile />} />

    <Route path="/hr/request/create" element={<AddHrRequest />} />
    <Route path="/hr/request/edit/:uuid" element={<EditHrRequest />} />
    <Route path="/hr/request/:uuid" element={<HrRequestProfile />} />

    <Route path="/hr/equipments/create" element={<AddHrEquipment />} />
    <Route path="/hr/equipments/edit/:uuid" element={<EditHrEquipment />} />
    <Route path="/hr/equipment/:uuid" element={<EquipmentProfile />} />
    <Route path="/hr/equipment/history/:uuid" element={<EquipmentHistory />} />

    <Route path="/recruiting" element={<Recruiting />} key="/recruiting">
      <Route index element={<Navigate to="/recruiting/vacancies" />} />
      <Route path="vacancies" element={<Vacancies />} />
      <Route path="documents" element={<RecruitingDocuments />} />
      <Route path="candidates" element={<Candidates />} />
      <Route path="candidates-events" element={<CandidatesEvents />} />
    </Route>

    <Route
      path="/recruiting/documents/create"
      element={<AddRecruitingDocument />}
    />
    <Route
      path="/recruiting/documents/edit/:uuid"
      element={<EditRecruitingDocument />}
    />
    <Route path="/recruiting/document/:uuid" element={<DocumentProfile />} />

    <Route
      path="/recruiting/vacancies/:uuid"
      element={<VacancyProfile />}
      key="/recruiting"
    >
      <Route
        index
        element={<Navigate to="/recruiting/vacancies/:uuid/description" />}
      />
      <Route path="description" element={<VacancyProfileDescription />} />
      <Route path="candidates" element={<VacancyProfileCandidates />} />
      <Route path="steps" element={<VacancyProfileSteps />} />
      <Route path="integration" element={<VacancyProfileIntegration />} />
      <Route path="notes" element={<VacancyProfileNotes />} />
      <Route path="documents" element={<VacancyProfileDocuments />} />
    </Route>

    <Route path="/recruiting/vacancies/create" element={<AddVacancy />} />
    <Route path="/recruiting/vacancies/edit/:uuid" element={<EditVacancy />} />
    <Route path="/recruiting/create-document/:uuid" element={<AddDocument />} />
    <Route path="/recruiting/edit-document/:uuid" element={<EditDocument />} />
    <Route
      path="/recruiting/candidates-events/create"
      element={<AddCandidatesEvent />}
    />
    <Route
      path="/recruiting/candidates-events/edit/:uuid"
      element={<EditCandidatesEvent />}
    />
    <Route
      path="/recruiting/candidates-events/:uuid"
      element={<CandidateEventProfile />}
    />

    <Route path="/recruiting/candidates/create" element={<AddCandidate />} />
    <Route
      path="/recruiting/candidates/edit/:uuid"
      element={<EditCandidate />}
    />

    <Route path="/recruiting/candidates/:uuid" element={<CandidateProfile />} key='/recruiting'>
      <Route index element={<Navigate to='/recruiting/candidates/:uuid/info' />} />
      <Route path='info' element={<CandidateInfo />} />
      <Route path='events' element={<CandidateEvents />} />
      <Route path='notes' element={<CandidateNotes />} />
    </Route>

    {/* <Route path="/analytics" element={<Analytics />} >
      <Route index element={<Navigate to='/employees' />} />
      <Route path='employees' element={<Employees />} />
      <Route path='age-of-employees' element={<AgeOfEmployees />} />
      <Route path='work-experience' element={<WorkExperience />} />
      <Route path='employee-turnover' element={<EmployeeTurnover />} />
    </Route> */}

    {/* <Route path="/administration" element={<Settings />} />
    <Route path="/administration/recruting" element={<SettingsRecruting />} />

    <Route path="/administration/vacations" element={<SettingsVacations />} key='/settingsVacations'>
      <Route index element={<Navigate to='/vacations' />} />
      <Route path='vacations' element={<Vacations />} />
      <Route path='hospitals' element={<Hospitals />} />
      <Route path='at-own-expense' element={<AtOwnExpense />} />
      <Route path='birthday-day-off' element={<BirthdayDayOff />} />
    </Route> */}

    {/* <Route path="/administration/calendars" element={<SettingsCalendars />} key='/settingsCalendars' >
      <Route index element={<Navigate to='/list' />} />
      <Route path='list' element={<Calendars />} />
    </Route>
    <Route path='/administration/calendars/:uuid' element={<EventDetails />} />

    <Route path="/administration/surveys" element={<SettingsSurveys />} key='/settingsSurveys' >
      <Route index element={<Navigate to='/list' />} />
      <Route path='list' element={<Surveys />} />
    </Route>
    <Route path='/administration/surveys/:uuid' element={<SurveyDetails />} /> */}

    {/* <Route path='administration/surveys/create-survey' element={<AddSurvey />} key='/createSurvey' />
    <Route path='administration/surveys/edit-survey/:uuid' element={<AddSurvey />} key='/editSurvey' />

    <Route path='administration/surveys/create_form' element={<AddForm />} key='/createForm' >
      <Route index element={<Navigate to='/question' />} />
      <Route path='question' element={<Question />} />
      <Route path='invites' element={<Invites />} />
    </Route> */}

    {/* <Route path='administration/surveys/edit_form/:uuid' element={<AddForm />} key='/editForm' >
      <Route index element={<Navigate to='/administration/surveys/edit_form/:uuid/question' />} />
      <Route path='question' element={<Question />} />
      <Route path='invites' element={<Invites />} />
    </Route> */}

    {/* <Route path="/administration/companies" element={<SettingsCompanies />} key='/settingsCompanies'>
      <Route index element={<Navigate to='/companies' />} />
      <Route path='companies' element={<Companies />} />
      <Route path='projects' element={<Projects />} />
      <Route path='vacations' element={<CompaniesVacations />} />
      <Route path='stages' element={<Stages />} />
    </Route> */}

    {/* <Route path='administration/companies/create-company' element={<AddCompany />} key='/createCompany' />
    <Route path='administration/companies/edit-company/:uuid' element={<AddCompany />} key='/editCompany' />
    <Route path='administration/companies/create-project' element={<AddProject />} key='/createProject' />
    <Route path='administration/companies/edit-project/:uuid' element={<AddProject />} key='/editProject' /> */}

    <Route path="/company" element={<Company />} key="/company">
      <Route index element={<Navigate to="/company/calendar" />} />
      <Route path="calendar" element={<CompanyCalendar />} />
      <Route path="calculator" element={<CompanyCalculator />} />
      <Route path="settings" element={<CompanySettings />} />
    </Route>

    <Route path="/opportunities" element={<OpportunitiesPage />} key="/opportunities">
      <Route index element={<OpportunitiesComponent />} />
    </Route>

    <Route path="/opportunities" element={<OpportunityDetails />}>
      <Route path="candidates/:uuid" element={<OpportunityDetailsCandidates />} />
      <Route index path="general/:uuid" element={<OpportunityDetailsGeneral />} />
      <Route path="stages/:uuid" element={<OpportunityDetailsStages />} />
      <Route path="integrations/:uuid" element={<OpportunityDetailsIntegrations />} />
      <Route path="comments/:uuid" element={<OpportunityDetailsComments />} />
    </Route>

    <Route path="*" element={<Navigate to="/dashboard" />} />
  </>
)

export default AdminRoutes