import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  routes: [],
}

const breadcrumbsSlice = createSlice({
  name: 'breadcrumbs',
  initialState,
  reducers: {
    addRoute: (state, action) => {
      const route = action.payload
      const newRoutes = [...state.routes]
      
      if (newRoutes[newRoutes.length - 1] !== route) {
        newRoutes.push(route)
        if (newRoutes.length > 3) {
          newRoutes.shift()
        }
      }
      
      state.routes = newRoutes
    },
  },
})


export const { addRoute } = breadcrumbsSlice.actions
export default breadcrumbsSlice.reducer
